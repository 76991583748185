import { deep } from '@theme-ui/presets'

export default {
  // Add breakpoints for responsive design
  breakpoints: ['40em', '52em', '64em'],

  colors: {
    text: '#2D3748',
    background: '#FFFFFF',
    primary: '#3182CE',
    secondary: '#718096',
    muted: '#F7FAFC',
    highlight: '#EDF2F7',
    accent: '#ED8936',
  },
  fonts: {
    body: 'system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading: 'inherit',
    monospace: 'Menlo, monospace',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  // Update space scale for more granular control
  space: [0, 4, 8, 16, 24, 32, 48, 64, 96, 128, 256, 512],
  sizes: {
    container: '800px',
    wide: '1200px',
  },
  layout: {
    container: {
      maxWidth: 'container',
      mx: 'auto',
      // More responsive padding that scales with viewport
      px: [3, 4, 5],
      py: [3, 4, 5],
    },
    wide: {
      maxWidth: 'wide',
      mx: 'auto',
      px: [3, 4, 5],
      py: [3, 4, 5],
    },
    content: {
      maxWidth: 'container',
      mx: 'auto',
      px: [3, 4, 5],
      py: [3, 4, 5],
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    h1: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [4, 5, 6],
      mt: [4, 5],
      mb: [3, 4],
    },
    h2: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [3, 4, 5],
      mt: [3, 4],
      mb: [2, 3],
    },
    h3: {
      color: 'text',
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
      fontSize: [2, 3, 4],
      mt: [3, 4],
      mb: [2, 3],
    },
    p: {
      color: 'text',
      fontFamily: 'body',
      fontWeight: 'body',
      lineHeight: 'body',
      mb: [2, 3],
      fontSize: [1, 2],
    },
    a: {
      color: 'primary',
      textDecoration: 'none',
      ':hover': {
        textDecoration: 'underline',
      },
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      p: [2, 3],
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
      p: [2, 3],
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
      p: [2, 3],
    },
  },
}
